import * as React from 'react'
import { Link } from 'gatsby'

import Page from '../components/Page'
import SEO from '../components/seo'
import Layout from '../layouts/layout'

const CharityPage = () => (
  <Layout>
    <SEO title="Charity" />
    <Page>
      <>
        <h1>Charity page</h1>
        <p>
          You've hit the void. <Link to="/">Go back.</Link>
        </p>
      </>
    </Page>
  </Layout>
)

export default CharityPage
